<template />

<script>
import useJwt from '@/auth/jwt/useJwt'
import { getUserData, decryptSSO } from '@/auth/utils'

export default {
  created() {
    const { code } = this.$route.params
    const { email, password } = decryptSSO(code)
    useJwt.login({
      email,
      password,
    })
      .then(async response => {
        const { accessToken, refreshToken } = response.data.data
        useJwt.setToken(accessToken)
        useJwt.setRefreshToken(refreshToken)
        let userData = await useJwt.getUserData()
        userData = userData.data.data

        localStorage.setItem('signature', userData)
        const user = getUserData()
        this.$ability.update(user.ability)
        window.location.href = 'https://dashboard.salesjarus.com'
      })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
